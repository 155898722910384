export default {
  ESIGNS_LIST: "Liste des packages",
  ADD_ESIGN: "Créer un nouveau package",
  ESIGNS: "Packages",
  PACKAGE: "Package",
  PACKAGES: "Packages",
  ESIGN_ADDED: "Package ajouté",
  EDIT_ESIGN: "Modifier un package",
  ESIGN_UPDATED: "Package mis à jour",
  DELETE_THIS_ESIGN: "Supprimer ce package ?",
  ESIGN_DELETED: "Package supprimé",
  ESIGN_TYPE: "Type de package",
  VIEW_ESIGN: "E-sign",
  PROPERTIES: "Propriétés",
  WITHOUT_ADDRESS: "Ne pas mettre d’adresse",
  RETURN_TO_FROM: "Retourner au formulaire",
  DRAFT: "Brouillon",
  SENT: "Envoyé",
  COMPLETED: "Complété",
  DECLINED: "Refusé",
  EXPIRED: "Expiré",
  EMAIL_MESSAGE: "Message à tous les destinataires (facultatif)",
  EDIT_TRANSACTION: "Gérer",
  EDITOR: "Editeur",
  CANNOT_EDIT: "Impossible de modifier ce package",
  CANNOT_DELETE: "Impossible de supprimer ce package",
  CALL_BACK_URL:"URL de rappel",
};
