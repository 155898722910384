export default {
    CODE:'Code',
    ACCOUNTS_LIST: "Liste des comptes",
    ADD_TRANSACTION: "Ajouter une transaction",
    TRANSACTIONS: "Paiements",
    ACCOUNTS: "Comptes",
    DPA_ACCOUNT_ADDED: "Compte ajouté",
    EDIT_ACCOUNT: "Modifier le compte",
    ADD_ACCOUNT: "Ajouter un compte",
    ADD_DPA_ACCOUNT: "Ajouter un compte",
    ACCOUNT_UPDATED: "Compte mis à jour",
    DELETE_THIS_ACCOUNT: "Supprimer ce compte ?",
    ACCOUNT_DELETED: "Compte supprimé",
    ACCOUNT_TYPE: "Type de compte",
    VIEW_ACCOUNT: "Compte DPA/PPA",
    RETURN_TO_FROM: "Retourner au formulaire",
    ISSUER_NUMBER: "Numéro d’émetteur",
    DATA_CENTER_CODE: "Code du centre de données",
    BANK_FORMAT: "Format de banque",
    FILE_NAME: "Nom du fichier",
    TRANSACTION_CALLBACK_URL: "Url de rappel",
    DEBIT: 'Débit',
    CREDIT: 'Crédit',
    PPA: 'Prélèvement pré-autorisé',
    DPA: 'Dépôt direct',
    VIEW_DPA_ACCOUNT: "Compte DPA/PPA",
    EDIT_DPA_ACCOUNT: "Modifier le compte",

    // File
    FILES_LIST: "Liste des transactions",
    FILES: "Transactions",
    PAIMENTS: "Paiements",
    DPA_FILE_ADDED: "Transaction ajoutée",
    EDIT_FILE: "Modifier la transaction",
    ADD_FILE: "Ajouter une transaction",
    ADD_DPA_FILE: "Ajouter une transaction",
    FILE_UPDATED: "Transaction mise à jour",
    DELETE_THIS_DPA_FILE: "Supprimer cette transaction ?",
    FILE_DELETED: "Transaction supprimée",
    FILE_TYPE: "Type de transaction",
    VIEW_FILE: "Transaction DPA/PPA",
    VIEW_DPA_FILE: "Transaction DPA/PPA",
    EDIT_DPA_FILE: "Modifier la transaction",
    
    TRANSACTION_TYPE: "Type",
    TOTAL_TRANSACTIONS: "Paiements",
    TOTAL_AMOUNT: "Montant Total",
    STATUS: "Statut",
    FILE_CREATION_NUMBER: "Numéro de crétion",
    SENT_AT: "Envoyé le",
    GENERATED_AT: 'Généré le',
    PROCESSED_AT: "Traité le",
    ERROR_MESSAGE: "Erreur",
    FILE: "Fichier",
    PROCESSING:"Traitement",
    CODE:'Code',
    deposit:'IN',
    withdraw:'OUT',
    generated:'Générée',
    failed:'Echec',
    processed:'Traitée',
    sent:'Envoyée',
    processing: 'En traitement',
    SELECT_TRANSACTIONS: "Veuillez sélectionner les paiements",
    DEPOSIT_DEMAND:"Dépôt ",
    WITHDRAW_DEMAND:"Paiement",
    SENT: 'Envoyé',
    PROCESSED: 'Traité',
    FAILED: 'Echoué',
    GENERATED: 'Généré',
    SEND_TO_BANK: 'Envoyer la demande à la banque',
    SEND_FILE_TO_BANK:'Envoyer à la banque',
    SEND_THIS_FILE: "Vous confirmez vouloir envoyer cette demande à votre banque ?",
    FILE_SENT: 'Demande envoyée avec succès !',
    CANNOT_DELETE_TRANSACTION: 'Vous ne pouvez plus supprimer cette transaction',
    CONFIRM_TRANSACTION: "Valider et confirmer",
    FILE_CONFIRMED:"Confirmation éffectuée avec succès !",
    CONFIRM_THIS_FILE:"Êtes-vous sûr de bien vouloir confirmer cette transaction et tout les paiments associés ?",
    AUTO_TRANSACTION_PROCESSING:"Traitement automatique des paiements",
    RETURN_ACCOUNT:"Compte de retour",
    AUTO_DAILY_PROCESS_TRANSACTION:"Traitement automatique des paiements en fin de journée et envoyer à la banque",
    NON:'Non',
    OUI:'Oui',
    BANK:"Banque",
    bnc:'Banque Nationale du Canada'
  };
  